<template lang="pug">
  p Saindo da conta. Aguarde...
</template>
<script>
import { mapActions } from 'vuex'
  export default{
    methods:{
      ...mapActions(['userLogout']),
    },
    mounted(){
      this.userLogout()
    }
  }
</script>
